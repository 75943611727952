import { Component } from '@angular/core'
import { EmmaService } from '@emmaServices/emma.service'
import { TranslateService } from '@ngx-translate/core'
import { BillingService } from '@services/billing/billing.service'
import { PCOverlayConfig, ServiceIds } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { AppService } from 'src/app/app.service'
import { DeviceBillingSubscription } from '../../../modules/device-management/services/device-billing.service'
import { DeviceService } from '../../../modules/device-management/services/device.service'

@Component({
  selector: 'app-services-root',
  styleUrls: ['./services-root.component.scss'],
  templateUrl: './services-root.component.html',
})
export class ServicesRootComponent {
  requiredDialog: PCOverlayConfig = {
    title: 'STORE.ROUTE_TITLE_FILL_BILLING_INFO',
  }

  checkoutDialog: PCOverlayConfig = {
    title: 'STORE.ROUTE_TITLE_CHECKOUT_DIALOG',
  }

  confirmationDialog: PCOverlayConfig = {
    title: 'STORE.ROUTE_TITLE_CONFIRMATION_DIALOG',
  }

  termsAccepted = false

  upgradeTermsAccepted = false

  constructor(
    public billing: BillingService,
    public deviceService: DeviceService,
    public emma: EmmaService,
    public app: AppService,
    public translate: TranslateService,
    public proficloud: ProficloudService,
    public ui: UiService
  ) {
    ;(window as any).root = this
  }

  /**
   *   The user has been informed that we require billing information, and clicked 'continue'
   */
  onContinueClick() {
    this.billing.showBillingAddressRequired$.next(false)
    this.billing.showBillingAddressForm$.next(true)
  }

  /**
   *    The user submits a billing account. Once it has been created, and the success message disappeard
   */
  onBillingAccountFormSubmit() {
    this.billing.createBillingAccount().subscribe((created) => {
      if (created) {
        this.billing.showCheckoutForm$.next(true)
      }
    })
  }

  /**
   *    The user want to complete a purchace
   */
  submitCheckoutForm() {
    // clear the reference
    this.billing.referenceInput = ''
    const cart = this.billing.billingDetails.shoppingCart
    if (!cart?.plan?.fields.sapNumber || !cart?.quantity) {
      console.warn('Billing info missing')
      return
    }

    // the below does nothing at the moment
    this.billing
      .createSubscription({
        packageId: this.billing.billingDetails.shoppingCart?.plan?.fields.sapNumber as string,
        quantity: this.billing.billingDetails.shoppingCart?.quantity as number,
      })
      .subscribe((success) => {
        if (success) {
          this.billing.fetchSubscriptions()

          // hide checkout, show confirmation
          this.billing.showCheckoutForm$.next(false)
          this.billing.showConfirmationForm$.next(true)
        }
      })
  }

  /**
   *    A purchase has been successfull create, the confirmation screen shown, and the user clicked on 'go to service'
   */
  submitConfirmationForm() {
    // close confirmation screen
    this.billing.showConfirmationForm$.next(false)

    // redirect to the service url
    const redirectUrl = this.billing.billingDetails.shoppingCart?.service?.fields.redirection[this.app.environment.backend]
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }

  /** Helpers for 'assign metrics/devices' offering */
  getAssignWording(service: ServiceIds = 'tsd') {
    const serviceWording: { [key in ServiceIds]?: string } = {
      tsd: 'Assign Metrics',
      ia: 'Assign Devices',
      emma: 'Assign Devices',
      dmsAddonBasic: 'Assign Devices',
      'tsd-prologis': 'Assign Devices',
    }
    return serviceWording[service || this.billing.bookedSubscription.smartServiceId]
  }

  onAssignButtonClick() {
    if (this.billing.bookedSubscription.smartServiceId === 'tsd') {
      this.billing.showAssignMetricsModal$.next(true)
    }

    if (this.billing.bookedSubscription.smartServiceId === 'ia') {
      this.billing.showAssignDevicesModal$.next({
        subscription: this.billing.bookedSubscription as any,
        candidateDevices: this.billing.iaCapableDevices,
        service: 'impulse',
      })
    }

    if (this.billing.bookedSubscription.smartServiceId === 'dmsAddonBasic' && this.deviceService.devices) {
      this.billing.showAssignDevicesModal$.next({
        subscription: this.billing.bookedSubscription as any,
        candidateDevices: this.deviceService.devices,
        service: 'dmsAddonBasic',
        devicesUsed: (this.billing.bookedSubscription as DeviceBillingSubscription).endpointIds, // TODO (aschoenenberg): Needs to be changed because the endpointIds are missing after cast
      })
    }
  }

  onConfirmUpgradeClick() {
    const packageId = this.billing.billingDetails.upgradePreview?.newSubscription.package.id
    if (!packageId) {
      console.warn('package ID missing')
      return
    }

    this.billing.upgradeSubscription(this.billing.billingDetails.upgradePreview?.subscription.id, {
      quantity: 1,
      packageId: this.billing.billingDetails.upgradePreview?.newSubscription.package.id as string,
      internalOrderId: this.billing.upgradeReference || '',
    })
  }

  closeUpgradeDialog() {
    this.upgradeTermsAccepted = false
    this.billing.showUpgradeDetails$.next(false)
  }
}
