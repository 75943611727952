import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'
import { BillingService } from '../../../services/billing/billing.service'

@Component({
  selector: 'app-billing-address-overlay',
  templateUrl: './billing-address-overlay.component.html',
  styleUrls: ['./billing-address-overlay.component.scss'],
})
export class BillingAddressOverlayComponent implements OnInit {
  @Output() closeClick = new EventEmitter()

  @Output() submitClick = new EventEmitter()

  constructor(public billing: BillingService) {}

  ngOnInit(): void {
    // Fire this off again to clear any values which had been entered but not saved.
    this.billing.createBillingAddressForm()
  }

  toggleCustomerNumber(): void {
    if (this.billing.hideCustomerNumberFieldControl.value) {
      this.billing.billingAddressForm.removeControl('pxcCustomerId')
      this.billing.billingFormCompanyFields.pop()
    } else {
      this.billing.billingFormCompanyFields.push({
        type: 'text',
        placeholder: 'Phoenix Contact Customer ID',
        key: 'pxcCustomerId',
        control: new UntypedFormControl(this.billing.billingAccount?.pxcCustomerId || '', [Validators.required, Validators.minLength(1)]),
        maxLength: 80,
      })
      this.billing.billingAddressForm.addControl(
        'pxcCustomerId',
        this.billing.billingFormCompanyFields[this.billing.billingFormCompanyFields.length - 1].control
      )
    }
  }
}
